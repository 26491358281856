import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Vi synar Open Web Docs och tittar närmare på framtiden för MDN. Terraria avbryter Stadia-satsningen, Twitter vill decentralisera, Rust skapar stiftelse och mycket mer.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Open Web Docs: `}<a parentName="li" {...{
          "href": "https://hacks.mozilla.org/2021/01/welcoming-open-web-docs-to-the-mdn-family/"
        }}>{`https://hacks.mozilla.org/2021/01/welcoming-open-web-docs-to-the-mdn-family/`}</a>{` `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/MDN/At_ten/History_of_MDN"
        }}>{`https://developer.mozilla.org/en-US/docs/MDN/At_ten/History_of_MDN`}</a>{` `}<a parentName="li" {...{
          "href": "https://opencollective.com/open-web-docs/updates/introducing-open-web-docs"
        }}>{`https://opencollective.com/open-web-docs/updates/introducing-open-web-docs`}</a></li>
    </ul>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`Ny Firefox-design: `}<a parentName="li" {...{
          "href": "https://lifehacker.com/how-to-try-out-the-upcoming-firefox-redesign-now-1846221037"
        }}>{`https://lifehacker.com/how-to-try-out-the-upcoming-firefox-redesign-now-1846221037`}</a></li>
      <li parentName="ul">{`CD PROJEKT RED Hackade: `}<a parentName="li" {...{
          "href": "https://twitter.com/CDPROJEKTRED/status/1359048125403590660"
        }}>{`https://twitter.com/CDPROJEKTRED/status/1359048125403590660`}</a></li>
      <li parentName="ul">{`Rust Foundation:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://blog.mozilla.org/blog/2021/02/08/mozilla-welcomes-the-rust-foundation/"
            }}>{`https://blog.mozilla.org/blog/2021/02/08/mozilla-welcomes-the-rust-foundation/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://foundation.rust-lang.org/posts/2021-02-08-hello-world/"
            }}>{`https://foundation.rust-lang.org/posts/2021-02-08-hello-world/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Twitter Bluesky: `}<a parentName="li" {...{
          "href": "https://www.theverge.com/2021/1/21/22242718/twitter-bluesky-decentralized-social-media-team-project-update"
        }}>{`https://www.theverge.com/2021/1/21/22242718/twitter-bluesky-decentralized-social-media-team-project-update`}</a></li>
      <li parentName="ul">{`Torvalds om 5.11:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://lkml.iu.edu/hypermail/linux/kernel/2102.0/08975.html"
            }}>{`http://lkml.iu.edu/hypermail/linux/kernel/2102.0/08975.html`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.techradar.com/news/linux-511-could-arrive-next-week-as-linus-torvalds-slams-the-superbowl"
            }}>{`https://www.techradar.com/news/linux-511-could-arrive-next-week-as-linus-torvalds-slams-the-superbowl`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Terraria (inte) på Stadia:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://twitter.com/Demilogic/status/1358661842147692549"
            }}>{`https://twitter.com/Demilogic/status/1358661842147692549`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.reddit.com/r/pcgaming/comments/lfbc8u/terraria_on_stadia_cancelled_after_developers/"
            }}>{`https://www.reddit.com/r/pcgaming/comments/lfbc8u/terraria_on_stadia_cancelled_after_developers/`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`"Hemliga" funktioner i Firefox: `}<a parentName="li" {...{
          "href": "http://exple.tive.org/blarg/2020/10/25/navigational-instruments/"
        }}>{`http://exple.tive.org/blarg/2020/10/25/navigational-instruments/`}</a></li>
      <li parentName="ul">{`Overwatch på Lutris: `}<a parentName="li" {...{
          "href": "https://medium.com/@egee_irl/how-to-install-play-overwatch-on-ubuntu-bf00b3bd7920"
        }}>{`https://medium.com/@egee_irl/how-to-install-play-overwatch-on-ubuntu-bf00b3bd7920`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Chatgate`}</li>
      <li parentName="ul">{`Rustäventyret`}<ul parentName="li">
          <li parentName="ul">{`Clippy`}</li>
          <li parentName="ul">{`Docker`}</li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`PineCast taget (tack Lukasz)`}</li>
      <li parentName="ul">{`Peter testar remotely.one, men överväger meshcentral`}</li>
      <li parentName="ul">{`Peter undrar: Vilken distro ska man välja?`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`YouTube: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Matrix: `}<a parentName="li" {...{
          "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
        }}>{`#TrevligMjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Night Of Mystery" av Alexander Nakarada `}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      